import React from "react";

const Footer = ()=>
{
    return (
        <>
           <footer>
                <p>Copyright © 2022 Dreamguys.</p>
            </footer>
        </>
    )
}

export default Footer;